import AdminLayout from '@/Layouts/AdminLayout.vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { createInertiaApp, usePage } from '@inertiajs/vue3'
import mitt from 'mitt'
import { createPinia } from 'pinia'
import 'tippy.js/dist/tippy.css'
import { computed, createApp, h } from 'vue'
import VueTippy from 'vue-tippy'
import { ZiggyVue } from 'ziggy-js/dist/vue'
import resetStore from '@/Stores/Plugins/resetStore'
import '../css/admin.css'

// noinspection JSUnresolvedReference
const isDev = ['local', 'develoment'].includes(process.env.NODE_ENV)
if (!isDev) {
    // noinspection JSUnresolvedReference
    Bugsnag.start({
        apiKey: '9a2cc6adcf071f41658b4945b1d84d9b',
        plugins: [new BugsnagPluginVue()],
        appVersion: __buildDate__,
        releaseStage: process.env.NODE_ENV,
        enabledReleaseStages: [ 'production', 'staging' ],
        onError: function (event) {
            const auth = computed(() => usePage().props.auth)
            const user = auth.value?.user ?? false
            if (user) {
                event.setUser(user.id, user.email, user.full_name)
            }
        }
    })
}

// noinspection JSIgnoredPromiseFromCall
createInertiaApp({
    progress: {
        delay: 250,
    },
    resolve: name => {
        /**
         * eager
         */
        // const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
        // return pages[`./Pages/${name}.vue`]
        /**
         * lazy
         * @see https://stackoverflow.com/a/72867218/4005858
         */
        const pages = import.meta.glob('./PagesAdmin/**/*.vue')
        let page = pages[`./PagesAdmin/${name}.vue`]()
        page.then(module => {
            module.default.layout = module.default.layout || AdminLayout
        })

        return page
    },
    setup({ el, App, props, plugin }) {
        const VueApp = createApp({ render: () => h(App, props) })

        if (!isDev) {
            VueApp.use(Bugsnag.getPlugin('vue'))
        }

        const pinia = createPinia()
        pinia.use(resetStore)
        VueApp.use(plugin)
            .use(ZiggyVue)
            .use(pinia)
            .use(VueTippy, {
                directive: "tippy",
                defaultProps: {
                    placement: 'bottom',
                    allowHTML: true,
                    delay: [500, null],
                },
            })
            .provide('csrf_token', props.initialPage.props.csrf_token)
            .provide('auth', props.initialPage.props.auth)
            .provide('emitter', mitt())
            .mount(el)
    },
})
